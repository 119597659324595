import {Block, Box, Columns, Hero, Image} from "react-bulma-components";

function Console() {
    return (
        <Hero
              mt={6}
              mb={4}
              // style={{
              //       backgroundImage:"url(https://d73d56r7cbg9q.cloudfront.net/testata-01.png)",
              //       backgroundPosition: 'center',
              //       backgroundSize: 'cover',
              //       backgroundRepeat: 'no-repeat',
              //     }}
          >
              <Columns
                centered={true}
                vcentered={true}
            >
                <Columns.Column
                    size={'one-third'}
                >
                        <Box>
                            <Block>
                                <Image
                                    src={'https://d73d56r7cbg9q.cloudfront.net/MarioMureddu_BrandID_Icona_Colore.png'}
                                    size={'square'}
                                />
                            </Block>
                        </Box>


                </Columns.Column>
                <Columns.Column
                    size={'one-third'}
                >
                    Da qui inizia la tua esperienza con noi, qui troverai ciò che abbiamo prodotto per te.
                </Columns.Column>
            </Columns>
          </Hero>
    );
}

export default Console