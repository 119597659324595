import {Block, Box, Button, Form, Columns, Hero, Image} from "react-bulma-components";
import {useState} from "react";


function Contact() {
  // const products = [
  // const [products, setProducts] = useState([]);
  //
  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await butter.content.retrieve(["cookies"], {
  //       order: "name",
  //     });
  //     const { data } = await res.data;
  //     const allProducts = data.cookies;
  //     setProducts(allProducts);
  //   }
  //   fetchData();
  // }, []);
    //
    //
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [company, setCompany] = useState('');

  // todo: add a control for adding a loader while the request is working
  let postContactRequest = async (e) => {
    e.preventDefault();
    try {
    let res = await fetch(
        process.env.REACT_APP_BE_URL + '/contact_mail',
        {
            method: 'POST',
            cors:true,
            body: JSON.stringify(
                {
                    'email': email,
                    'company':company,
                    'name':name,
                    "message":message
                }
            )
        }
    );
    let resJson = await res.json();
    if (resJson.status === 200) {
        setName("");
        setEmail("");
        setMessage("User created successfully");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
      <div>
          <Hero
              mt={6}
              mb={4}
              // style={{
              //       backgroundImage:"url(https://d73d56r7cbg9q.cloudfront.net/testata-01.png)",
              //       backgroundPosition: 'center',
              //       backgroundSize: 'cover',
              //       backgroundRepeat: 'no-repeat',
              //     }}
          >
              <Columns
                centered={true}
                vcentered={true}
            >
                <Columns.Column
                    size={'one-third'}
                >
                        <Box>
                            <Block>
                                <Image
                                    src={'https://d73d56r7cbg9q.cloudfront.net/MarioMureddu_BrandID_Icona_Colore.png'}
                                    size={'square'}
                                    alt={'Mario Mureddu Consulting'}
                                />
                            </Block>
                        </Box>


                </Columns.Column>
                <Columns.Column size={'one-third'}>
                    <form>
                      <Form.Field>
                        <Form.Label>Email</Form.Label>
                        <Form.Control>
                          <Form.Input
                            onChange={(e) => {
                              return setEmail(e.target.value);
                            }}
                            placeholder="mail@dominio.xx"
                          />
                        </Form.Control>
                      </Form.Field>
                      <Form.Field>
                        <Form.Label>Nome</Form.Label>
                      </Form.Field>
                      <Form.Field>
                          <Form.Control fullwidth>
                            <Form.Input
                                placeholder="Nome"
                                onChange={
                                    (e) => {
                                      return setName(e.target.value);
                                    }
                                }
                            />
                          </Form.Control>
                      </Form.Field>

                      <Form.Field>
                          <Form.Label>Azienda</Form.Label>
                      </Form.Field>
                      <Form.Field>
                          <Form.Control fullwidth>
                            <Form.Input
                                placeholder="Azienda"
                                onChange={
                                    (e) => {
                                      return setCompany(e.target.value);
                                    }
                                }
                            />
                          </Form.Control>
                      </Form.Field>

                      <Form.Field>
                        <Form.Label>A cosa sei interessato?</Form.Label>
                        <Form.Textarea
                          placeholder="Messaggio"
                          onChange={(e) => {
                            return setMessage(e.target.value);
                          }}
                        />
                      </Form.Field>



                      <Form.Field>
                        <Form.Control>
                          <Button
                              color="primary"
                              onClick={postContactRequest}
                          >
                              Submit
                          </Button>
                        </Form.Control>
                      </Form.Field>
                    </form>
                </Columns.Column>
            </Columns>
          </Hero>
      </div>

  );
}

export default Contact;
