import {Card, Columns, Media, Heading, Content, Button, Block, Hero, Icon} from 'react-bulma-components';
import { FiShoppingCart } from "react-icons/fi";


function Shop() {
  const products = [
    {
      "meta": {
        "id": "Info"
      },
      "id": "Info",
      "name": "Redazione informative",
      "price": 350,
      "description": "Redazione informative privacy per utenza e dipendenti",
      "image": "https://d73d56r7cbg9q.cloudfront.net/1_mini.png"
    },
    {
      "meta": {
        "id": "Doc"
      },
      "id": "Doc",
      "name": "Documentazione completa GDPR",
      "price": 5000,
      "description": "Creazione documentazione completa GDPR ",
      "image": "https://d73d56r7cbg9q.cloudfront.net/2_mini.png"
    },
    {
      "meta": {
        "id": "ML"
      },
      "id": "ML",
      "name": "Modello di machine learning GDPR-compliant",
      "price": 5000,
      "description": "Creazione e implementazione cloud di un modello di machine learning GDPR compliant",
      "image": "https://d73d56r7cbg9q.cloudfront.net/3_mini.png"
    }
  ]
  // let stage = 'production';
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //         stage = 'development';
  //   }
  // const [products, setProducts] = useState([]);
  //
  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await butter.content.retrieve(["cookies"], {
  //       order: "name",
  //     });
  //     const { data } = await res.data;
  //     const allProducts = data.cookies;
  //     setProducts(allProducts);
  //   }
  //   fetchData();
  // }, []);

  return (
      <div>
        <Hero
            p={4}
            style={{
                  background:"linear-gradient(90deg, #FEBD46, #EE2927)"
              }}
        >
          <Columns
              pt={6}
          >
            {products.map((product) => (
                <Columns.Column
                    size={'one-fifth'}
                >
                  <Card
                      style={{ margin: 'auto' }}
                      justifyContent={"space-around"}
                  >
                    <Card.Image
                      size="2by1"
                      src={product.image}
                      alt={product.name}
                    />
                    <Card.Content>
                      <Media.Item>

                        <Heading
                            size={4}
                            textAlign={'center'}
                            style={{ height: 80 }}
                        >
                          {product.name}
                        </Heading>

                        <Heading
                            subtitle size={6}
                            textAlign={'center'}
                            textColor={'success'}
                            textWeight={'bold'}
                            style={{ height: 80 }}
                        >
                           {product.price} €
                        </Heading>

                      </Media.Item>
                      <Content
                          textAlign={'center'}
                          style={{ height: 80 }}
                      >
                        <Block>
                          {product.description}
                        </Block>
                      </Content>
                    </Card.Content>
                    <Card.Footer>
                      <Card.Footer.Item>
                        <Button
                          className="snipcart-add-item"
                          data-item-id={product.id}
                          data-item-image={product.id}
                          data-item-name={product.name}
                          data-item-url={
                            process.env.REACT_APP_PUBLIC_URL + '/products_' + process.env.REACT_APP_STAGE + '.json'
                          }
                          data-item-description={product.description}
                          data-item-price={product.price}
                          style={{
                            backgroundColor:"#FB883B",
                            borderColor:"#FB883B",
                            textColor:"#FAFAFA",
                          }}
                        >
                          <Icon>
                                <FiShoppingCart/>
                          </Icon>
                          &nbsp;&nbsp;&nbsp;{"Add to Cart"}
                        </Button>
                      </Card.Footer.Item>

                    </Card.Footer>

                  </Card>
                </Columns.Column>
              ))
            }
          </Columns>
        </Hero>
      </div>

    // <Container maxW="container.xl" h="100vh" >
    //   <Flex justifyContent="space-between" alignContent="center">
    //     <Text
    //       as="a"
    //       href="/"
    //       fontSize="2rem"
    //       color="gray.900"
    //       fontFamily="Robo"
    //       my="5px"
    //     >
    //       E-Commerce Mario Mureddu Consulting
    //     </Text>
    //     <Button
    //       my="5px"
    //       colorScheme="green"
    //       variant="ghost"
    //       leftIcon={<FiShoppingBag size="24px" />}
    //       size="lg"
    //       p={2}
    //       className="snipcart-checkout"
    //     >
    //       View Cart
    //     </Button>
    //   </Flex>
    //   <Divider />
    //   <Box mt={4}>
    //     <SimpleGrid
    //       minChildWidth="300px"
    //       align="center"
    //       justify="center"
    //       spacing="40px"
    //       mb={32}
    //     >
    //       {products.map((product) => (
    //         <Box
    //           bg="white"
    //           maxW="sm"
    //           borderWidth="1px"
    //           rounded="lg"
    //           shadow="lg"
    //           _hover={{ shadow: "dark-lg" }}
    //           key={product.id}
    //         >
    //           <Image
    //             h="350px"
    //             fit="cover"
    //             src={product.image}
    //             alt={`Picture of ${product.name}`}
    //             roundedTop="lg"
    //           />
    //
    //           <Box p="6">
    //             <Flex
    //               mt="1"
    //               justifyContent="space-between"
    //               alignContent="center"
    //             >
    //               <Text
    //                 fontSize="2xl"
    //                 fontWeight="semibold"
    //                 as="h4"
    //                 textTransform="uppercase"
    //                 lineHeight="tight"
    //                 fontFamily="Roboto"
    //               >
    //                 {product.name}
    //               </Text>
    //               <Text
    //                 as="h4"
    //                 fontSize="2xl"
    //                 fontWeight="bold"
    //                 color="teal.600"
    //               >
    //                 ${product.price}
    //               </Text>
    //             </Flex>
    //
    //             <Text
    //               mt={2}
    //               color="gray.500"
    //               display={{ base: "none", md: "flex" }}
    //             >
    //               {product.description}
    //             </Text>
    //
    //             <Button
    //               leftIcon={<FiShoppingCart size="24px" />}
    //               size="lg"
    //               mt={4}
    //               isFullWidth
    //               colorScheme="blue"
    //               variant="outline"
    //               alignSelf={"center"}
    //               className="snipcart-add-item"
    //               data-item-id={product.id}
    //               data-item-image={product.image}
    //               data-item-name={product.name}
    //               data-item-url="/"
    //               data-item-description={product.description}
    //               data-item-price={product.price}
    //             >
    //               Add to Cart
    //             </Button>
    //           </Box>
    //         </Box>
    //       ))}
    //     </SimpleGrid>
    //   </Box>
    // </Container>
  );
}

export default Shop;
