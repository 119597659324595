import React, { Component } from 'react';
import { Route, Router, Switch } from "wouter";
import NavbarMMC from './components/Navbar'
import Home from './components/Home'
import Shop from './components/Shop'
import Contact from './components/Contact'
import Footer from "./components/Footer";
import RequireAuth from "./components/RequireAuth";
import Console from "./components/Console";
import './css/mystyles.css'

class App extends Component {
  render() {
    return (
       <Router>
            <div className="App">
                <NavbarMMC
                    transparent={true}
                />
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/shop" component={Shop}/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/console" component={RequireAuth(Console)} />
                </Switch>
                <Footer/>
             </div>
       </Router>

    );
  }
}

export default App;