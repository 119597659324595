import { Link } from "wouter";
import {Block, Box, Button, Card, Columns, Content, Hero, Image} from "react-bulma-components";


function Home() {
  // const products = [
  // const [products, setProducts] = useState([]);
  //
  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await butter.content.retrieve(["cookies"], {
  //       order: "name",
  //     });
  //     const { data } = await res.data;
  //     const allProducts = data.cookies;
  //     setProducts(allProducts);
  //   }
  //   fetchData();
  // }, []);

  return (
      <div>
          <Hero
              // style={{
              //       backgroundImage:"url(https://d73d56r7cbg9q.cloudfront.net/testata-01.png)",
              //       backgroundPosition: 'center',
              //       backgroundSize: 'cover',
              //       backgroundRepeat: 'no-repeat',
              //     }}
          >
              <Columns
                  // style={{
                  //     margin: 25
                  //   }}
                  mt={5}
                  mb={5}
              >
                  <Columns.Column size={'full'}>
                    <Columns
                        centered={true}
                        vcentered={true}
                    >
                        <Columns.Column
                            size={'one-third'}
                        >
                                <Content
                                    textAlign={'left'}
                                    ml={5}
                                    textFamily={"secondary"}

                                >
                                    <h1>
                                        <Block>
                                            <br/>
                                            <br/>
                                            <br/>
                                            Consulenze di
                                        </Block>
                                        <Block>
                                            digitalizzazione
                                        </Block>
                                        <Block>
                                            aziendale
                                        </Block>
                                    </h1>

                                    <ul>
                                        <li>
                                            <Block
                                                textSize={4}
                                                italic={true}
                                            >
                                                Intelligenza Artificiale
                                            </Block>
                                        </li>
                                        <li>
                                            <Block
                                                textSize={4}
                                                italic={true}
                                            >
                                                Protezione Dati
                                            </Block>
                                        </li>
                                        <li>
                                            <Block
                                                textSize={4}
                                                italic={true}
                                            >
                                                Blockchain
                                            </Block>
                                        </li>
                                    </ul>

                                </Content>


                        </Columns.Column>
                        <Columns.Column size={'one-third'}>
                            <Box>
                                <Block>
                                    <Image
                                        src={'https://d73d56r7cbg9q.cloudfront.net/MarioMureddu_BrandID_Icona_Colore.png'}
                                        size={'square'}
                                        alt={'Mario Mureddu Consulting'}
                                    />
                                </Block>
                            </Box>

                        </Columns.Column>
                    </Columns>
                  </Columns.Column>
              </Columns>
          </Hero>
          <Hero
              pt={2}
              // color={'pink'}
              style={{
                  background:"linear-gradient(90deg, #FEBD46, #EE2927)"
              }}
          >
              <Hero.Body>
                  <Columns
                      mt={2}
                      mb={2}
                  >
                      <Columns.Column
                          size={'one-fifth'}
                          offset={'one-fifth'}
                      >
                          <Card>
                              <Card.Image
                                  src={'https://d73d56r7cbg9q.cloudfront.net/ai_icon.png'}
                                  alt={'Intelligenza Artificiale'}
                              />
                              <Card.Content>
                                  <Content
                                      pt={1}
                                  >
                                      <h1>Intelligenza artificiale</h1>
                                      Automatizza i tuoi processi
                                  </Content>
                              </Card.Content>
                          </Card>
                      </Columns.Column>
                      <Columns.Column
                          size={'one-fifth'}
                      >
                          <Card>
                              <Card.Image
                                  src={'https://d73d56r7cbg9q.cloudfront.net/Protezione_dati_icon.png'}
                                  alt={'Protezione Dati'}
                              />
                              <Card.Content>
                                  <Content
                                      pt={1}
                                  >
                                      <h1>Protezione dati</h1>
                                      Adeguati al GDPR
                                  </Content>
                              </Card.Content>
                          </Card>
                      </Columns.Column>
                      <Columns.Column
                          size={'one-fifth'}
                      >
                          <Card>
                              <Card.Image
                                  src={'https://d73d56r7cbg9q.cloudfront.net/Blockchain_icon.png'}
                                  alt={'Blockchain'}
                              />
                              <Card.Content>
                                  <Content
                                      pt={1}
                                  >
                                      <h1>Blockchain </h1>
                                  </Content>
                                  <Content
                                      pt={5}
                                  >Crea il tuo smart contract</Content>
                              </Card.Content>
                          </Card>
                      </Columns.Column>


                  </Columns>
              </Hero.Body>

          </Hero>
          <Hero
              pb={6}
          >
            <Columns>
                <Columns.Column
                    size={2}
                />
                <Columns.Column
                    size={'one-third'}
                    pt={6}

                >
                    <Content
                        textAlign={'center'}
                    >
                        Sei interessato ad una quotazione personalizzata?
                    </Content>
                    <Content
                        textAlign={'center'}
                    >
                        <Button
                            renderAs={Link} color="primary" to="/contact"
                        >
                            Contattaci!
                        </Button>
                    </Content>
                </Columns.Column>
                <Columns.Column
                    size={'one-third'}
                    pt={6}
                >
                    <Content
                        textAlign={'center'}
                    >
                        Sai già cosa acquistare?
                    </Content>
                    <Content
                        textAlign={'center'}
                    >
                        <Button
                            renderAs={Link} color="primary" to="/shop"
                        >
                            Vai allo store
                        </Button>
                    </Content>


                </Columns.Column>
            </Columns>
          </Hero>
      </div>


//       <div className="e207_4">
//           <div className="e2_8">
//               <div className="e2_9"></div>
//               <div className="e203_12">
//                   <div className="ei203_12_203_16"><span className="ei203_12_2_11">Acquista</span></div>
//               </div>
//               <div className="e2_12"><span className="e2_18">Consulenze di digitalizzazione aziendale</span>
//                   <div className="e2_19"><span className="e2_20">Intelligenza artificiale</span></div>
//                   <div className="e2_21"><span className="e2_22">Protezione Dati</span></div>
//                   <div className="e2_23"><span className="e2_24">Blockchain</span></div>
//                   <div className="e203_3"></div>
//               </div>
//
//           </div>
//           <div className="e14_7">
//               <div className="e14_11">
//                   <div className="e14_12">
//                       <div className="e14_13">
//                           <div className="e14_14">
//                               <div className="e14_15">
//                                   <div className="e14_16">
//                                       <div className="e14_17">
//                                           <div className="e14_18">
//                                               <div className="e14_19"></div>
//                                           </div>
//                                       </div>
//                                       <div className="e14_20">
//                                           <div className="e14_21">
//                                               <div className="e14_22"></div>
//                                           </div>
//                                       </div>
//                                       <div className="e14_23">
//                                           <div className="e14_24">
//                                               <div className="e14_25"></div>
//                                           </div>
//                                       </div>
//                                       <div className="e14_26">
//                                           <div className="e14_27">
//                                               <div className="e14_28"></div>
//                                           </div>
//                                       </div>
//                                   </div>
//                               </div>
//                               <span className="e14_29">Intelligenza
// Artificiale</span><span className="e14_30">Automatizza
// i tuoi processi</span></div>
//                       </div>
//                       <div className="e14_36">
//                           <div className="e14_37">
//                               <div className="e14_38">
//                                   <div className="e14_39">
//                                       <div className="e14_40"></div>
//                                       <div className="e14_41"></div>
//                                       <div className="e14_42"></div>
//                                       <div className="e14_43"></div>
//                                       <div className="e14_44"></div>
//                                       <div className="e14_45"></div>
//                                       <div className="e14_46"></div>
//                                       <div className="e14_47"></div>
//                                       <div className="e14_48"></div>
//                                       <div className="e14_49"></div>
//                                   </div>
//                               </div>
//                               <span className="e14_50">Protezione
// dati</span><span className="e14_51">Adeguati al GDPR
// </span></div>
//                       </div>
//                       <div className="e14_57">
//                           <div className="e14_58">
//                               <div className="e14_59">
//                                   <div className="e14_60">
//                                       <div className="e14_61">
//                                           <div className="e14_62">
//                                               <div className="e14_63"></div>
//                                               <div className="e14_64"></div>
//                                               <div className="e14_65"></div>
//                                               <div className="e14_66"></div>
//                                           </div>
//                                       </div>
//                                   </div>
//                               </div>
//                               <span className="e14_67">Blockchain
// </span><span className="e14_68">Crea il tuo
// smart contract</span></div>
//                       </div>
//                   </div>
//               </div>
//           </div>
//           <div className="e9_40">
//               <div className="e203_28">
//                   <div className="e203_29"><span className="e203_30">Contattaci</span></div>
//               </div>
//               <span className="e203_31">Sei interessato a una
// quotazione personalizzata?</span>
//               <div className="e203_4">
//                   <div className="e203_16"><span className="e2_11">Acquista</span></div>
//               </div>
//               <span className="e203_32">Sai già cosa acquistare?</span></div>
//           <div className="e2_134">
//               <div className="e2_135">
//                   <div className="e2_136">
//                       <div className="e2_137">
//                           <div className="e2_138"><span className="e2_139">Informazioni aziendali</span>
//                               <div className="e2_140"><span className="e2_141">Ragione sociale: Mario Mureddu</span><span
//                                   className="e2_142">P.IVA: 03791190923</span></div>
//                           </div>
//                           <div className="e2_159"><span className="e2_160">Contatti</span>
//                               <div className="e2_161">
//                                   <div className="e2_162">
//                                       <div className="e2_163">
//                                           <div className="e2_164"></div>
//                                       </div>
//                                       <span className="e2_165">+39 351 9088944</span></div>
//                                   <div className="e2_171">
//                                       <div className="e2_172">
//                                           <div className="e2_173"></div>
//                                       </div>
//                                       <span className="e2_174">info@mariomureddu.com</span></div>
//                               </div>
//                           </div>
//                       </div>
//                   </div>
//               </div>
//           </div>
//       </div>
    // <Container maxW="container.xl" h="100vh" >
    //   <Flex justifyContent="space-between" alignContent="center">
    //     <Text
    //       as="a"
    //       href="/"
    //       fontSize="2rem"ß
    //       color="gray.900"
    //       fontFamily="Robo"
    //       my="5px"
    //     >
    //       E-Commerce Mario Mureddu Consulting
    //     </Text>
    //     <Button
    //       my="5px"
    //       colorScheme="green"
    //       variant="ghost"
    //       leftIcon={<FiShoppingBag size="24px" />}
    //       size="lg"
    //       p={2}
    //       className="snipcart-checkout"
    //     >
    //       View Cart
    //     </Button>
    //   </Flex>
    //   <Divider />
    //   <Box mt={4}>
    //     <SimpleGrid
    //       minChildWidth="300px"
    //       align="center"
    //       justify="center"
    //       spacing="40px"
    //       mb={32}
    //     >
    //       {products.map((product) => (
    //         <Box
    //           bg="white"
    //           maxW="sm"
    //           borderWidth="1px"
    //           rounded="lg"
    //           shadow="lg"
    //           _hover={{ shadow: "dark-lg" }}
    //           key={product.id}
    //         >
    //           <Image
    //             h="350px"
    //             fit="cover"
    //             src={product.image}
    //             alt={`Picture of ${product.name}`}
    //             roundedTop="lg"
    //           />
    //
    //           <Box p="6">
    //             <Flex
    //               mt="1"
    //               justifyContent="space-between"
    //               alignContent="center"
    //             >
    //               <Text
    //                 fontSize="2xl"
    //                 fontWeight="semibold"
    //                 as="h4"
    //                 textTransform="uppercase"
    //                 lineHeight="tight"
    //                 fontFamily="Roboto"
    //               >
    //                 {product.name}
    //               </Text>
    //               <Text
    //                 as="h4"
    //                 fontSize="2xl"
    //                 fontWeight="bold"
    //                 color="teal.600"
    //               >
    //                 ${product.price}
    //               </Text>
    //             </Flex>
    //
    //             <Text
    //               mt={2}
    //               color="gray.500"
    //               display={{ base: "none", md: "flex" }}
    //             >
    //               {product.description}
    //             </Text>
    //
    //             <Button
    //               leftIcon={<FiShoppingCart size="24px" />}
    //               size="lg"
    //               mt={4}
    //               isFullWidth
    //               colorScheme="blue"
    //               variant="outline"
    //               alignSelf={"center"}
    //               className="snipcart-add-item"
    //               data-item-id={product.id}
    //               data-item-image={product.image}
    //               data-item-name={product.name}
    //               data-item-url="/"
    //               data-item-description={product.description}
    //               data-item-price={product.price}
    //             >
    //               Add to Cart
    //             </Button>
    //           </Box>
    //         </Box>
    //       ))}
    //     </SimpleGrid>
    //   </Box>
    // </Container>
  );
}

export default Home;
