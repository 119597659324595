import {FaPhone, FaMailBulk} from "react-icons/fa"
import {Columns, Content, Hero, Icon} from "react-bulma-components";

const Footer = ()=>{
    return (
        <Hero
          pt={2}
          // color={'pink'}
          style={{
              background:"#333333"
          }}
      >
        <Columns>
            <Columns.Column
                size={2}
            />
            <Columns.Column
                size={'one-third'}
                pt={6}

            >
                <Content
                    textAlign={'center'}
                    textColor={"white"}
                    textSize={4}
                >
                    INFORMAZIONI AZIENDALI
                </Content>
                <Content
                    textAlign={'center'}
                    textColor={'white'}
                    pb={6}
                >
                    <ul>
                        <li>
                            Ragione sociale: Mario Mureddu
                        </li>
                        <li>
                            P.IVA: 03791190923
                        </li>
                    </ul>

                </Content>
            </Columns.Column>
            <Columns.Column
                size={'one-third'}
                pt={6}
            >
                <Content
                    textAlign={'center'}
                    textSize={4}
                    textColor={'white'}
                >
                    CONTATTI
                </Content>
                <Content
                    textAlign={'center'}
                    textColor={'white'}
                >
                    <ul>
                        <li style={{ listStyleType: "none" }}>
                            <Icon>
                                <FaPhone/>
                            </Icon>+39 351 9088944
                        </li>
                        <li style={{ listStyleType: "none" }}>
                            <Icon>
                                <FaMailBulk/>
                            </Icon>info@mariomureddu.com
                        </li>
                    </ul>
                </Content>
            </Columns.Column>
        </Columns>
      </Hero>
    )
}

export default Footer;