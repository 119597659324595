import React from 'react';
// import { Link } from 'react-router-dom'
import { Navbar} from 'react-bulma-components';

const NavbarMMC = ()=>{
    return(
        <Navbar
            size={'large'}
            // fixed={'top'}
            // style={{
            //         backgroundImage:'url("https://d73d56r7cbg9q.cloudfront.net/testata-01.png")',
            //         backgroundPosition: 'center',
            //         backgroundSize: 'cover',
            //         backgroundRepeat: 'no-repeat'
            //       }}
        >
            <Navbar.Brand>
                <Navbar.Item href="/">
                  <img
                    alt="Mario Mureddu Consulting"
                    height={5}
                    src="https://d73d56r7cbg9q.cloudfront.net/MarioMureddu_logo_small.jpg"
                  />
                </Navbar.Item>
                <Navbar.Burger />
            </Navbar.Brand>
            <Navbar.Container align="right">
                <Navbar.Item href="/">
                    Home
                </Navbar.Item>
                <Navbar.Item href="/shop">
                    ECommerce
                </Navbar.Item>
                <Navbar.Item href="/contact">
                    Contattaci
                </Navbar.Item>
                <Navbar.Item href="/console">
                    Area Riservata
                </Navbar.Item>

            </Navbar.Container>
        </Navbar>
    )
}

export default NavbarMMC;